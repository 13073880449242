import React from 'react';
import { motion } from 'framer-motion';

const partnershipApproaches = [
  {
    title: "Innovative Dual Channel Approach",
    description: "We employ a strategic dual channel approach, combining direct sales with carefully selected distribution partnerships to maximize market reach and efficiency."
  },
  {
    title: "Collaborative Research Initiatives",
    description: "Endolastic actively engages in collaborative research projects with leading academic institutions and healthcare providers to continually advance our technology and clinical outcomes."
  },
  {
    title: "Customized Training Programs",
    description: "We offer comprehensive, tailored training programs to ensure our partners and their medical staff are fully equipped to utilize Endolastic technologies effectively."
  },
  {
    title: "Global Expansion Strategy",
    description: "Our partnership strategy is designed to facilitate global expansion, bringing Endolastic's innovative solutions to patients worldwide through strategic alliances in key markets."
  }
];

const distributorRequirements = [
  "Established presence in the medical device market, particularly in gastroenterology and bariatrics",
  "Strong relationships with hospitals, clinics, and key opinion leaders",
  "Capability to provide excellent customer service and technical support",
  "Willingness to embrace innovative distribution models and direct-to-consumer strategies",
  "3PL capabilities or willingness to develop them for select markets",
  "Commitment to ongoing training and education for healthcare providers"
];

const keyMarkets = [
  { region: "North America", strategy: "Blend of traditional distribution and direct-to-consumer pilots in select urban centers" },
  { region: "Europe", strategy: "Focus on building strong distributor networks with potential for future direct-to-consumer expansion" },
  { region: "Asia-Pacific", strategy: "Partnerships with distributors who have strong e-commerce and logistics capabilities" },
  { region: "Middle East", strategy: "Traditional distribution model with a focus on centers of excellence" },
  { region: "Latin America", strategy: "Hybrid approach, leveraging distributors' local expertise for both channels" }
];

const partnerBenefits = [
  "Be at the forefront of a revolutionary distribution model in medical devices",
  "Access to cutting-edge, patented technologies with high growth potential",
  "Opportunity to diversify revenue streams through traditional and direct-to-consumer channels",
  "Comprehensive training and ongoing support for both distribution models",
  "Collaborative approach to market development and clinical research",
  "Potential to become a regional hub for innovative healthcare delivery"
];

function Partners() {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
            Our Partnership Approach
          </h1>
          <p className="text-xl md:text-2xl mb-8 max-w-3xl">
            Collaborating with leading healthcare providers and institutions to bring innovative solutions to patients worldwide.
          </p>
        </div>
      </header>

      <main className="py-16">
        <div className="container mx-auto px-4">
          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-8 text-center">Our Innovative Partnership Strategy</h2>
            <div className="grid md:grid-cols-2 gap-8">
              {partnershipApproaches.map((approach, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-white rounded-lg shadow-md p-6"
                >
                  <h3 className="text-xl font-semibold mb-2">{approach.title}</h3>
                  <p className="text-gray-600">{approach.description}</p>
                </motion.div>
              ))}
            </div>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-8 text-center">What We're Looking For in Distribution Partners</h2>
            <div className="bg-white rounded-lg shadow-md p-8">
              <ul className="list-disc pl-5 space-y-2">
                {distributorRequirements.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
            </div>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-8 text-center">Key Markets and Expansion Goals</h2>
            <p className="text-xl mb-6 text-center">
              Our dual-channel model is tailored to meet the unique needs of different global markets:
            </p>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {keyMarkets.map((market, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-white rounded-lg shadow-md p-6"
                >
                  <h3 className="text-xl font-semibold mb-2">{market.region}</h3>
                  <p className="text-gray-600">{market.strategy}</p>
                </motion.div>
              ))}
            </div>
            <p className="text-xl mt-8 text-center">
              Our goal is to make Endolastic's solutions available in 50+ countries within the next five years, bringing hope and improved quality of life to patients worldwide through our innovative dual-channel approach.
            </p>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-8 text-center">Why Partner with Endolastic?</h2>
            <div className="bg-white rounded-lg shadow-md p-8">
              <ul className="list-disc pl-5 space-y-2">
                {partnerBenefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
          </section>



          {/* Add this new section at the bottom of the main content */}
          <section className="mt-16 bg-orange-100 rounded-lg p-8">
            <h2 className="text-3xl font-bold mb-6 text-center">Join Us in Reshaping Global Healthcare Distribution</h2>
            <p className="text-xl mb-8">
              Whether you're an established medical device distributor looking to innovate or a forward-thinking company ready to pioneer new healthcare delivery models, we invite you to explore partnership opportunities with Endolastic. Together, we can bring cutting-edge, minimally invasive solutions to patients around the world while setting new standards in medical device distribution.
            </p>
            <div className="text-center">
              <a
                href="/contact"
                className="inline-block bg-orange-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-orange-700 transition duration-300"
              >
                Contact Us to Explore Dual-Channel Opportunities
              </a>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

export default Partners;